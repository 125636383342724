export default {
  GET_ROOM_LIST: 'GET_ROOM_LIST',
  GET_TIME_TABLE: 'GET_TIME_TABLE',
  GET_ROOM: 'GET_ROOM',
  GET_VIDEO_ROOM: 'GET_VIDEO_ROOM',
  GET_LINKS_ROOM: 'GET_LINKS_ROOM',
  GET_CHAT_LIVE_LIST: 'GET_CHAT_LIVE_LIST',
  GET_TIME_TABLE_DETAILS: 'GET_TIME_TABLE_DETAILS',
  CHAT_LOAD: 'CHAT_LOAD',
};
