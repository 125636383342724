import './assets/less/app.less';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Preloader from './components/ui/Preloader';
import { $root } from './global';
import * as serviceWorker from './serviceWorker';
import { store } from './store/configureStore';
const App = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Preloader />}>
      <App />
    </Suspense>
  </Provider>,
  $root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
