import { IRoomsAction, IRoomsState } from '../../services/IRooms';
import types from './types';

const initialState: IRoomsState = {
  list: null,
  timeTable: null,
  room: null,
  videoRoom: null,
  linksRoom: null,
  timeTableDetails: null,
  chatLiveList: null,
  chatLoad: null,
  // params: {
  //   id: null,
  //   type: null,
  // },
};

export default function roomsReducer(state: IRoomsState = initialState, action: IRoomsAction): IRoomsState {
  switch (action.type) {
    case types.GET_ROOM_LIST:
      return { ...state, list: action.list };

    case types.GET_TIME_TABLE:
      return { ...state, timeTable: action.timeTable };

    case types.GET_ROOM:
      return { ...state, room: action.room };

    case types.GET_VIDEO_ROOM:
      return { ...state, videoRoom: action.videoRoom };

    case types.GET_LINKS_ROOM:
      return { ...state, linksRoom: action.linksRoom };

    case types.GET_TIME_TABLE_DETAILS:
      return { ...state, timeTableDetails: action.timeTableDetails };

    case types.GET_CHAT_LIVE_LIST:
      return { ...state, chatLiveList: action.chatLiveList };

    case types.CHAT_LOAD:
      return { ...state, chatLoad: action.chatLoad };

    default:
      return state;
  }
}
